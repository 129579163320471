<template>
  <plain-layout>
    <div
    class="columns is-marginless is-flex-direction-column
     is-justify-content-space-between is-align-items-center"
    >
      <div class="column is-6 is-narrow px-6">
          <b-message v-if="alert"
          has-icon
          :title="alert.title"
          :type="alert.type"
          aria-close-label="Close message">
          {{ alert.message }}
        </b-message>

        <h1 class="has-text-centered title mb-6">Get started</h1>
        <form @submit.prevent="proceed" class="mb-5">
          <b-field
            label="Email Address"
            custom-class="has-text-centered"
            :type="errorEmail ? 'is-danger' : ''"
            :message="errorEmail"
          >
            <b-input custom-class="custum-input" v-model="email"> </b-input>
          </b-field>
        </form>
        <div class="columns is-centered">
          <div class="column">
            <b-button
              type="is-primary"
              expanded
              @click="proceed"
              class="has-text-weight-bold"
            >
              Proceed
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </plain-layout>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { emailValidation } from '@/services/validation';
import getCurrentUser from '@/services/firebase';
import { auth } from '@/pluging/firebase';

import PlainLayout from '@/Plain';

export default {
  metaInfo() {
    return {
      title: 'Login',
    };
  },
  components: { PlainLayout },
  data() {
    return {
      email: '',
      errorEmail: '',
      time: 4000,
      alert: null,
    };
  },
  computed: {
    ...mapGetters({
      userEmail: 'email',
      userAuth: 'auth',
      settings: 'settings',
    }),
  },
  methods: {
    ...mapMutations({
      setEmail: 'SET_EMAIL',
      setUserId: 'SET_USER_ID',
      resetStore: 'RESET',
    }),
    async proceed() {
      let valid = true;
      if (!emailValidation(this.email)) {
        valid = false;
        const that = this;
        this.errorEmail = 'Email address is not valid';
        setTimeout(() => {
          that.errorEmail = '';
        }, this.time);
      }
      if (!valid) return;
      const loadingComponent = this.$buefy.loading.open();
      try {
        const actionCodeSettings = {
          url: window.location.href,
          handleCodeInApp: true,
        };
        await auth.sendSignInLinkToEmail(this.email, actionCodeSettings);
        this.$buefy.toast.open({
          message: 'Check your mailbox to complete the process',
          type: 'is-primary',
        });
        this.setEmail(this.email);
        this.$router.push({ name: 'VerifyEmail' });
        loadingComponent.close();
      } catch (error) {
        this.$buefy.toast.open({
          message: error.message,
          type: 'is-danger',
        });
        loadingComponent.close();
      }
    },
    async validSign() {
      if (auth.isSignInWithEmailLink(window.location.href)) {
        let email = this.userEmail;
        if (!this.userEmail) {
          // eslint-disable-next-line no-alert
          email = window.prompt('Please provide your email for confirmation');
        }
        const loadingComponent = this.$buefy.loading.open();
        try {
          const res = await auth.signInWithEmailLink(email, window.location.href);
          this.setUserId(res.user.uid);
          this.$router.push({ name: 'VerifyUserAccount' });
          loadingComponent.close();
        } catch (error) {
          this.$buefy.toast.open({
            message: error.message,
            type: 'is-danger',
          });
          loadingComponent.close();
        }
      }
    },
  },
  async mounted() {
    if (this.$route.query.apiKey) {
      await this.validSign();
    } else {
      try {
        const user = await getCurrentUser();
        if (user) {
          if (user.isAnonymous) {
            await auth.signOut();
            this.resetStore();
          } else {
            this.$router.replace({ name: 'VerifyUserAccount' });
          }
        }
      } catch (e) {
        //
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.params.alert) {
          this.alert = route.params.alert;
        }
        if (route.params.email) {
          this.email = route.params.email;
        }
      },
    },
  },
};
</script>
